import { useAppSelector } from 'util/hooks/hooks';
import { useTranslation } from 'react-i18next';

import { useGetDashboardItemsQuery } from 'api/dashboardSlice';
import { useGetInstallationsQuery } from 'api/installationsSlice';

import { dashBoardGroupsColumns } from 'constants/groupsColumns';
import { dashBoardBrandsColumns } from 'constants/brandsColumns';
import { logsTableColumns } from 'constants/logsColumns';

import { Container } from 'components/common/Container';
import DashboardWidget from './DashboardWidget';
import LoadingSkeleton from 'components/common/LoadingSkeleton';

const Dashboard = () => {
  const { data, isLoading } = useGetDashboardItemsQuery();
  const { data: installationData } = useGetInstallationsQuery({ includeAllShops: true });
  const { user } = useAppSelector(state => state.authUser);
  const { t } = useTranslation();
  const filteredInstallations = installationData?.data ?
      installationData.data.filter(installation => installation.name !== 'LexiMarket') :
      [];
  return (
    <Container className="py-8">
      <h1 className="flex justify-center py-4 text-md">
        {t('dashboard.greeting', { username: user?.name })}
      </h1>
      {!isLoading && data && installationData ? (
        <div className="flex flex-col gap-8 my-8">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <DashboardWidget
              headerText="groups"
              failureText="dashboard.unconfigured"
              redirectPath="/groups"
              data={data?.groups}
              columns={dashBoardGroupsColumns}
              installations={filteredInstallations}
            />
            <DashboardWidget
              headerText="brands"
              failureText="dashboard.unconfigured"
              redirectPath="/brands"
              data={data?.brands}
              columns={dashBoardBrandsColumns}
              installations={filteredInstallations}
            />
          </div>
          <DashboardWidget
            headerText="failed_inserts"
            failureText="dashboard.failure"
            redirectPath="/logs"
            data={data?.failed_inserts}
            columns={logsTableColumns}
            installations={installationData?.data || []}
          />
        </div>
      ) : (
        <LoadingSkeleton />
      )}
    </Container>
  );
};
export default Dashboard;
