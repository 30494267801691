import { apiSlice } from './apiSlice';

import { Dictionary } from 'typings/common/react';
import { AttributeGroupItem, Installation } from 'typings/entity/installations';
import { SelectOption } from 'typings/form/form';
import { GenericResponse } from '../typings/api/common';

const installationsSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getInstallations: builder.query<GenericResponse<Installation[]>, void | { includeAllShops?: boolean }>({
      query: (params = {}) => {
        const includeAllShops = params && 'includeAllShops' in params ? params.includeAllShops : false;
        const queryParams = includeAllShops ? '?shops=all' : '';

        return {
          url: `/installations${queryParams}`,
          method: 'GET',
        };
      },
    }),
    getInstallationCategories: builder.query({
      query: (installationId: number) => ({
        url: `/installations/${installationId}/categories`,
        method: 'GET',
        providesTags: ['Category'],
      }),
    }),
    getInstallationAttributeGroups: builder.query<AttributeGroupItem[], number>(
      {
        query: (installationId: number) => ({
          url: `/installations/${installationId}/attribute-groups`,
          method: 'GET',
          providesTags: ['Atrribute'],
        }),
      }
    ),
    getInstallationShops: builder.query({
      query: (installationId: number) => ({
        url: `/installations/${installationId}/shops`,
        method: 'GET',
      }),
    }),
    getInstallationBrands: builder.query({
      query: (installationId: number) => ({
        url: `/installations/${installationId}/brands`,
        method: 'GET',
      }),
    }),
    getInstallationCarriers: builder.query({
      query: (installationId: number) => ({
        url: `/installations/${installationId}/carriers`,
        method: 'GET',
      }),
    }),
    getInstallationParams: builder.query<Dictionary<SelectOption[]>, number>({
      query: (installationId: number) => ({
        url: `/installations/${installationId}/params`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetInstallationsQuery,
  useGetInstallationAttributeGroupsQuery,
  useGetInstallationParamsQuery,
} = installationsSlice;
