import { Tab } from '@headlessui/react';
import { useSearchParams } from 'react-router-dom';

import { useGetInstallationsQuery } from 'api/installationsSlice';

import { setDefaultTab } from 'util/tableHelpers';

import { Container } from 'components/common/Container';
import TabHeader from 'components/common/TabHeader';

import LoadingSkeleton from 'components/common/LoadingSkeleton';

import ConfigPanel from './ConfigPanel';

const Configuartion = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { data } = useGetInstallationsQuery({ includeAllShops: true });

  const urlTab = parseInt(searchParams.get('tab') || '1');

  return (
    <Container
      className="flex pt-10 justify-between md:px-0 z-0 relative"
      withPadding={false}
    >
      <div className="w-full">
        {data?.data && (
          <Tab.Group
            defaultIndex={setDefaultTab(data?.data || [], urlTab)}
            onChange={(e: number) =>
              data?.data &&
              setSearchParams({ tab: data?.data[e].id.toString() })
            }
          >
            <div className="sticky top-0 z-50 pt-2  bg-gray-100 flex flex-col lg:flex-row lg:justify-between">
              <TabHeader data={data?.data} />
            </div>

            <Tab.Panels className="pt-4">
              {data?.data?.map(({ id }, idx) => (
                <Tab.Panel key={`table-${idx}`}>
                  <ConfigPanel id={id} />
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        )}
        {!data?.data && <LoadingSkeleton />}
      </div>
    </Container>
  );
};

export default Configuartion;
